import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const FlexPage = () => (
  <Layout>
    <SEO title="Flex" />
    <div className="edge">
        <h1>Flex</h1>
        <p className="text-preset-large-intro">Coming soon</p>
    </div>
  </Layout>
)

export default FlexPage
